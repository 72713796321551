import {
  createAction,
  actionTypeCreator,
  post,
} from 'relient/actions';

const actionType = actionTypeCreator('contact');

export const UPDATE = actionType('UPDATE');
export const CREATE = actionType('CREATE');

export const create = createAction(
  CREATE,
  ({
    name,
    phone,
    email,
    message,
  }) => post('/contact', {
    name,
    phone,
    email,
    message,
  }),
);
