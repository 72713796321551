import { combineReducers } from 'redux';
import { createReducer } from 'relient/reducers';
import contact from './contact';
import global from './global';

export default combineReducers({
  ...createReducer([
    contact,
  ]),
  ...global,
});
