import cnMessages from '../messages/cn';
import enMessages from '../messages/en';

export const CN = 'cn';
export const EN = 'en';
export const LANGUAGE_COOKIE = 'language';

export const messagesMap = {
  [CN]: cnMessages,
  [EN]: enMessages,
};
