import { merge, handleActions, combineActions } from 'relient/reducers';
import { contact } from '../schema';
import {
  CREATE,
} from '../actions/contact';

export default {
  contact: handleActions({
    [combineActions(
      CREATE,
    )]: merge({ schema: contact }),

  }, {}),
};
