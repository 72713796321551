export default {
  home: '首页',
  ownersEntry: '房东入口',
  residentsEntry: '租客入口',
  aboutUs: '关于我们',
  contactUs: '联系我们',
  slogan: '专业管理团队，省心、省时、省力',
  serviceInstruction: '服务介绍',
  serviceTitle1: '租前服务',
  serviceDescription1: '租金价格分析，出租屋照片拍摄，广告（20多家主流英文出租房产网站），租客联系，租客筛选，带客看房，租客背景调查（收入，信用分数，犯罪记录，前房东反馈等），签署租约，',
  serviceTitle2: '租后管理',
  serviceDescription2: '收取租金，日常维护，日常维修与处理租客报修，提供月度收支对账单，年底提供报税文件。新管理的房产，外居乐可协调小型维修与专业清洁等服务。',
  whyUs: '为什么选择我们？',
  whyUsTitle1: '标题1',
  whyUsTitle2: '标题2',
  whyUsTitle3: '标题3',
  whyUsTitle4: '标题4',
  whyUsTitle5: '标题5',
  whyUsTitle6: '标题6',
  whyUsDescription1: 'WJL HomeServices 出租管理公司，是华盛顿州注册的专业出租管理公司 （出租管理执照号码#20293）。是外居乐的直属子公司，在西雅图地区管理超过600套房产，包括独栋别墅，联排别墅和公寓，是西雅图地区最大华人出租管理公司。团队成员毕业于美国的杜克大学，乔治城大学，维克森林大学，华盛顿大学等名校，专注在出租管理领域，有丰富的出租管理经验。',
  whyUsDescription2: '外居乐建立了广泛的供应商网络，可以快速处理房产维护和维修问题，节省房东的时间，费用和精力。',
  whyUsDescription3: '外居乐管理公司，是华盛顿州出租管理协会（RHAWA）与全国住宅出租管理协会（NARPM）的会员，定期参加行业协会举办的法律、法规培训。团队成员熟悉华盛顿州的各级法律法规。与行业内知名房产律师事务所合作，有处理各种复杂情况的经验。',
  whyUsDescription4: '我们了解市场与趋势，通过三重数据分析，准确设定租金，每周七天无间断带客看房，实现房屋快速出租。独栋别墅平均出租时间在2.5周，比行业平均时间快40%。',
  whyUsDescription5: '外居乐为每个房东提供网上个人账号，提供详细的月度和年度报表，分项列出各类收入与支出费用，财务、纳税信息一目了然。',
  whyUsDescription6: '我们对待房东的房屋就如同对待自己的房屋一样，落实租金的按时收取，及时为租客提供服务，对房产进行定期检查与提出维护建议。',
  teamInstruction: '团队介绍',
  coFounder: '联合创始人',
  marketingDirector: '市场总监',
  financeManager: '财务经理',
  JeoDescription1: '中美财务和金融市场经验',
  JeoDescription2: 'Eaton（财富500）融资与并购高级经理',
  JeoDescription3: '投资经理，高盛银行',
  JeoDescription4: '杜克大学MBA (Duke University)',
  MelissaDescription1: '住宅与商业地产管理经验',
  MelissaDescription2: '华盛顿州注册房产经纪人',
  MelissaDescription3: '乔治城大学(Georgetown University) 地产硕士',
  TonyDescription1: '华盛顿大学 (University of Washington)',
  feeInstruction: '收费介绍',
  feeTitle1: '1. 如何收管理费？',
  feeDescription1: '独栋别墅，提供两种收费体系：{br}1. 招租客费用，一个月租金的50％。第二个月收取管理费，为月租金收入的8％，费率根据房产的状况和位置有调整{br}2. 不收取招租费用，管理费用为月租金10% 的固定服务费',
  feeTitle2: '2. 什么时候开始收管理费？',
  feeDescription2: '收到第一笔租金后，开始收取服务费用。房屋招租与空置期间，不收取管理费。',
  feeTitle3: '3. 房产的水电等费用帐单是否要房东分摊？',
  feeDescription3: '大多数情况下，租客承担水、电、垃圾帐单费用。外居乐协助租客完成账单过户。',
  customerReviews: '用户点评',
  customerName1: 'Louis Carlson',
  customerReview1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
  customerName2: 'Amy Clark',
  customerReview2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
  contact: '联系方式',
  phone: '电话',
  ChinaPhone: '中国用户可以直接拨打 185-2143-3955',
  USAPhone: '美国用户可以直接拨打 425-896-8350',
  email: '邮件',
  USAAddress: '美国总部地址：西雅图',
  ChinaAddress: '中国总部地址：上海市浦东新区',
  followWechatAccount: '关注公众号',
  followWechatAccountDescription: '关注外居乐公众号获取租房资讯',
  downloadApp: '下载APP',
  downloadAppDescription: '外居乐美国房产网APP掌上租房更便捷',
  copyRight: '© 2021 外居乐美国房产网 版权所有 | 沪ICP备15046544号-2',
  name: '姓名',
  message: '留言',
  submit: '提交',
  contactSuccess: '联系成功，我们将尽快回复您，谢谢！',
  contacted: '已联系',
};
